<template>
  <div class="Index">
    <div class="dashboard-title">
      <div class="title">{{ $t("lang.NText155") }}</div>
    </div>
    <div class="dashboard-line1">
		<img src="../assets/images/Monthly.png" style="width: 100%">
    </div>
  </div>
</template>

<script>
import template from "./Booking/template.vue";
import dwTimeLine from "../components/dwTimeline/dwTimeLine.vue";
import mapboxGl from "../components/map/mapboxGl";
export default {
  components: { template, dwTimeLine, mapboxGl },
  data() {
    return {
      quotationNumber: 0,
      bookingNumber: 0,
      shipmentNumber: 0,
      tabType: 1,
      stepList: [
        {
          name: "Pick up",
          zh_name: "取货",
          text: "Ningbo,CN",
        },
        {
          name: "Origin port",
          zh_name: "启运港",
          text: "Shanghai,CN",
        },
        {
          name: "Discharge port",
          zh_name: "卸货港",
          text: "LosAngeies",
        },
        {
          name: "Destination",
          zh_name: "目的地",
          text: "Destination",
        },
      ],
      TotalByCustomer: [],
      searchInput: "", // MSDU5775460
      loading: false,
      isMap: false,
      trackingObj: {
        CurrentLocation: "",
        CurrentExplain: "",
        CurrentTime: "",
        EsLocation: "",
        EsExplain: "",
        EsTime: "",
        currList: [],
        HBLNO: "",
        fromLocation: "",
        toLocation: "",
      },
      TotalByCustomer: [],
      IncomeDataList: [],
      annualData: {
        yAxis: [],
        data: [],
      },
      searchInput: "",
      drawMonthlyData: [],
      color: ["#132569", "#299be4", "#eb4b4b", "#b3dcf6"],
      userinfo: {},
      teuNumber: null,
      noymData: [],
      options: [],
    };
  },
  created() {},
  mounted() {
    this.findBookingConfirmCount();
    this.findQuoteConfirmCount();
    setTimeout(() => {
      this.$session("userInfo").get((value) => {
        this.userinfo = value;
        // this.customerTeuList();
        // this.trackingList();
      });
    }, 100);
  },
  methods: {
    drawMonthlyLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("Monthly"));
      let that = this;
      // 绘制图表
      myChart.setOption({
        title: {
          text: "Monthly volume",
          textStyle: {
            color: "#B2B2B2",
            fontFamily: "montserratLight",
            fontSize: 16,
          },
          padding: [0, 0, 0, 30],
        },
        tooltip: {},
        grid: {
          top: "20%",
          left: "10%",
          right: "0",
          bottom: "30%",
        },
        xAxis: {
          data: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          align: "center",
        },
        legend: {
          orient: "horizontal",
          left: "right",
          icon: "circle", //设置图例图标的形状为实心圆，这个不填，默认是矩形
          itemHeight: 9, //图例图标的高度
          itemWidth: 9, //图例图标的宽度
          itemGap: 25, //图例图标与文字间的间距
          textStyle: {
            fontSize: 12, //图例文字字体大小
            color: "#8A90A3", //图例文字颜色
          },
        },
        yAxis: {},
        series: that.drawMonthlyData,
      });
    },
    drawAnnualLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("Annual"));
      let that = this;
      // 绘制图表
      myChart.setOption({
        title: {
          text: "Annual volume",
          textStyle: {
            color: "#B2B2B2",
            fontFamily: "montserratLight",
            fontSize: 16,
          },
          padding: [0, 0, 0, 50],
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        // legend: {
        //   orient: "vertical",
        //   left: "right",
        // },
        grid: {
          top: "20%",
          left: "3%",
          right: "4%",
          bottom: "20%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: that.annualData.yAxis,
        },
        series: [
          {
            type: "bar",
            data: that.annualData.data,
            color: "#299be4",
            barWidth: 8,
          },
        ],
      });
    },
    drawTradeLine() {
      let myChart = this.$echarts.init(document.getElementById("tradeLine"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "item",
        },
        // legend: {
        //   orient: "vertical",
        //   left: "right",
        // },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            top: "20%",
            left: "3%",
            right: "4%",
            bottom: "20%",
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            color: ["#132569", "#299be4", "#eb4b4b", "#b3dcf6"],
            labelLine: {
              show: true,
            },
            data: this.noymData,
          },
        ],
      });
    },
    findBookingConfirmCount() {
      this.$ajax
        .cPost(this.source.quotation_api.findQuoteConfirmCount)
        .then((res) => {
          if ((res.resultCode = "success")) {
            this.quotationNumber = res.data.count;
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    findQuoteConfirmCount() {
      this.$ajax
        .cPost(this.source.booking_api.findBookingConfirmCount)
        .then((res) => {
          if ((res.resultCode = "success")) {
            this.bookingNumber = res.data.count;
          } else {
            this.$notify.error({
              title: res.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    customerTeuList() {
      let that = this;
      let ref = {
        selectType: "20",
        dateType: "etd",
        showType: "10",
        // customer: "NS-KIRKLABW",
        customer: this.userinfo.customerCode || "-999",
        tabletype: "teu",
      };
      if (
        sessionStorage.getItem("annualData") &&
        sessionStorage.getItem("drawMonthlyData") &&
        sessionStorage.getItem("noymData")
      ) {
        setTimeout(() => {
          that.annualData = JSON.parse(sessionStorage.getItem("annualData"));
          that.drawAnnualLine();
          that.drawMonthlyData = JSON.parse(
            sessionStorage.getItem("drawMonthlyData")
          );
          that.drawMonthlyLine();
          that.noymData = JSON.parse(sessionStorage.getItem("noymData"));
          this.teuNumber = sessionStorage.getItem("teuNumber");
          that.drawTradeLine();
        }, 100);
      } else {
        this.$ajax.cPost(this.source.basApi_api.customerTeuList, ref).then((res) => {
            if ((res.data.result = "true") && res.data.fcl) {
              let yearList = res.data.fcl.yearList;
              let noymData = res.data.fcl.noym;
              yearList.forEach((value) => {
                that.annualData.yAxis.push(value.year);
                that.annualData.data.push(value.num);
                let bb = [];
                value.list.forEach((value2) => {
                  bb.push(value2.num);
                });
                let seriesData = {
                  data: bb,
                  name: that.annualData.yAxis[this.drawMonthlyData.length],
                  color: this.color[this.drawMonthlyData.length],
                  type: "bar",
                };
                this.drawMonthlyData.push(seriesData);
              });
              this.teuNumber = res.data.fcl.sum;
              noymData.forEach((item) => {
                let name = item.showTypeName.split("(");
                var name2 = "";
                name.forEach((a, i) => {
                  name2 = name2 + a;
                  if (i == 0) {
                    name2 = name2 + "\n";
                  }
                  if (i < name.length - 1) {
                    name2 = name2 + "(";
                  }
                });
                this.noymData.push({
                  value: item.num,
                  name: name2,
                });
              });
              sessionStorage.setItem("teuNumber", res.data.fcl.sum);
              sessionStorage.setItem(
                "annualData",
                JSON.stringify(that.annualData)
              );
              sessionStorage.setItem(
                "drawMonthlyData",
                JSON.stringify(that.drawMonthlyData)
              );
              sessionStorage.setItem("noymData", JSON.stringify(that.noymData));
              this.drawMonthlyLine();
              this.drawTradeLine();
              this.drawAnnualLine();
            } else {
              this.$notify.error({
                title: res.message,
                offset: 100,
                duration: 3000,
              });
            }
          });
      }
    },

    toQuotation() {
      let obj = JSON.parse(sessionStorage.getItem("@menuList")).find((res) => {
        return res.menuName == "Quotation";
      });
      sessionStorage.setItem("menu", JSON.stringify(obj));
      this.$emit("jumpRoute");
      this.$router.push("/Quotation/quotation");
    },
    toBooking() {
      let obj = JSON.parse(sessionStorage.getItem("@menuList")).find((res) => {
        return res.menuName == "Booking";
      });
      sessionStorage.setItem("menu", JSON.stringify(obj));
      this.$emit("jumpRoute");
      this.$router.push("/Booking");
    },
    clickTab(num) {
      this.tabType = num;
    },
    clickSpot(row, index) {
      this.$nextTick(() => {
        this.$refs.mapboxGL.initmap(row, index, true);
      });
    },
    TrackClick() {
      if (!this.searchInput) return;
      this.loading = true;
      this.isMap = false;
      this.$ajax
        .cPost(this.source.tracking_api.queryTracking, {
          selectNo: this.searchInput,
        })
        .then((res) => {
          if (res.data.result == "true") {
            let arr = res.data.tracking[0]["trackingSub"];
            let cascaderVal = [
              arr[0]["HBLNO"],
              arr[0]["containerNumberListMap"][0]["containerNumberL"],
            ];
            let resObj = arr.find((item) => item.HBLNO == cascaderVal[0]);
            let OBJECT = resObj.currList.find(
              (el) => el.eventType == "EmptyReturn"
            );
            if (OBJECT) {
              this.trackingObj.CurrentLocation = "";
              this.trackingObj.CurrentExplain = "Complete";
              this.trackingObj.CurrentTime = OBJECT.eventTime;
              this.trackingObj.EsLocation = "";
              this.trackingObj.EsExplain = "";
              this.trackingObj.EsTime = "";
              this.trackingObj.HBLNO = resObj.HBLNO;
              this.trackingObj.fromLocation = resObj.fromLocation;
              this.trackingObj.toLocation = resObj.toLocation;
            } else {
              let reqObj = resObj.containerNumberListMap.find(
                (req) => req.containerNumberL == cascaderVal[1]
              );
              this.trackingObj.CurrentLocation =
                reqObj.currentNewStatus.split(" , ")[0];
              this.trackingObj.CurrentExplain =
                reqObj.currentNewStatus.split(" , ")[1];
              this.trackingObj.CurrentTime = reqObj.currentTime;
              this.trackingObj.EsLocation = reqObj.currentNewStatus_next
                ? reqObj.currentNewStatus_next.split(" , ")[0]
                : "";
              this.trackingObj.EsExplain = reqObj.currentNewStatus_next
                ? reqObj.currentNewStatus_next.split(" , ")[1]
                : "";
              this.trackingObj.EsTime = reqObj.currentTime_next;
              this.trackingObj.HBLNO = resObj.HBLNO;
              this.trackingObj.fromLocation = resObj.fromLocation;
              this.trackingObj.toLocation = resObj.toLocation;
            }
            this.trackingObj.currList =
              arr[0]["containerNumberListMap"][0]["currList"];
            this.isMap = true;
          } else {
            this.trackingObj = {
              CurrentLocation: "",
              CurrentExplain: "",
              CurrentTime: "",
              EsLocation: "",
              EsExplain: "",
              EsTime: "",
              currList: [],
              HBLNO: "",
              fromLocation: "",
              toLocation: "",
            };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchInputChange(value) {
      this.TrackClick();
    },
    trackingList() {
      this.$ajax
        .cPost(this.source.tracking_api.trackingList, {
          customer: this.userinfo.customerCode || "-999",
          // customer: "NS-KIRKLABW",
        })
        .then((res) => {
          if (res.data.list.length > 0) {
            this.searchInput = res.data.list[0]["container_no"];
            this.TrackClick();
          }
          this.options = res.data.list;
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang='scss'>
@import "../assets/base.scss";
.Index {
  text-align: left;
  .dashboard-title {
    height: 24px;
    width: 100%;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i5Zu+5bGCXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNDggMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ4IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojMTMyNTY5O30NCgkuc3Qxe2ZpbGw6I0ZGRkZGRjt9DQo8L3N0eWxlPg0KPGc+DQoJPGc+DQoJCTxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMCwyNCAwLDAgMTIsMCAJCSIvPg0KCTwvZz4NCgk8Zz4NCgkJPHBvbHlnb24gY2xhc3M9InN0MSIgcG9pbnRzPSIxMiwwIDAsMjQgMTIsMjQgMjQsMCAJCSIvPg0KCTwvZz4NCgk8Zz4NCgkJPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIyNCwwIDEyLDI0IDI0LDI0IDM2LDAgCQkiLz4NCgk8L2c+DQoJPGc+DQoJCTxwb2x5Z29uIGNsYXNzPSJzdDEiIHBvaW50cz0iMzYsMCAyNCwyNCAzNiwyNCA0OCwwIAkJIi8+DQoJPC9nPg0KCTxnPg0KCQk8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9IjQ4LDAgNDgsMjQgMzYsMjQgCQkiLz4NCgk8L2c+DQo8L2c+DQo8L3N2Zz4NCg==");
    margin-bottom: 14px;
    .title {
      width: 166px;
      margin: auto;
      line-height: 24px;
      background-color: $mainColor;
      border-left: 15px solid #fff;
      border-right: 15px solid #fff;
      color: #fff;
      text-align: center;
    }
  }
  .dashboard-model {
    padding-right: 20px;
    flex: 1;
    .model-title {
      font-size: 30px;
      color: $mainColor;
      display: flex;
      justify-content: space-between;
      height: 54px;
      .title-svg {
        color: #fff;
        width: 36px;
        height: 24px;
      }
      .title-text {
        display: flex;
      }
      .title-text-type {
        font-size: 16px;
        display: flex;
        color: #9b9b9b;
        margin-left: 60px;
        line-height: 20px;
        height: 25px;
        .tabItem {
          margin-left: 20px;
          cursor: pointer;
        }
        .tabItemClick {
          color: #299be4;
          border-bottom: 2px solid #299be4;
        }
      }
    }
    .model-main {
      .noData {
        color: $background4;
        font-size: 30px;
        line-height: 60px;
        text-align: center;
        width: 100%;
      }
      .Data {
        display: flex;
        .number {
          color: $mainColor;
          font-size: 60px;
          font-family: "SEMIBOLD";
        }
        .text {
          font-size: 20px;
          margin-top: 10px;
          margin-left: 11px;
          .text1 {
            color: $mainColor;
            font-family: "montserratLight";
            line-height: 35px;
          }
          .text2 {
            font-size: 12px;
            color: $fontColor1;
          }
        }
      }
    }
  }
  .left-svg {
    height: 54px;
    width: 54px;
    position: absolute;
    left: 0;
  }
  .dashboard-line1 {
    display: flex;
    justify-content: space-between;
    position: relative;
    /*padding-left: 80px;*/
    .dashboard-model {
      .model-main {
        display: flex;
        justify-content: space-between;
        .button {
          margin-top: 20px;
          height: 30px;
          color: #299be4;
          font-size: 20px;
          border-bottom: 2px solid #299be4;
          cursor: pointer;
        }
      }
    }
  }
  .dashboard-line2 {
    position: relative;
    padding-left: 80px;
    margin-top: 60px;
    .model-main {
      display: flex;
      .svg-icon {
        fill: $mainColor;
        stroke: $mainColor;
        width: 76px;
        height: 76px;
      }
      .freightNumber {
        display: flex;
        height: 80px;
        .number {
          margin-left: 10px;
          width: 150px;
          text-align: center;
          font-size: 40px;
        }
        .freightNumber-teu {
          font-size: 20px;
          color: $mainColor;
          margin: 10px 20px;
        }
        .gantan {
          width: 20px;
          height: 20px;
          font-size: 10px;
          background-color: #f2f2f2;
          line-height: 20px;
          text-align: center;
          border-radius: 10px;
          margin: 10px 0;
          cursor: pointer;
        }
      }
      /*#Monthly {*/
		  /*position: relative;*/
		  /*padding: 0 20px;*/
		  /*img {*/
			  /*position: absolute;*/
			  /*left: 0;*/
			  /*top: -70px;*/
		  /*}*/
        /*height: 1000px;*/
      /*}*/
      /*#Annual {*/
      /*  width: 414px;*/
      /*  height: 200px;*/
      /*  padding: 0 20px;*/
      /*}*/
      /*#tradeLine {*/
      /*  width: 460px;*/
      /*  height: 200px;*/
      /*}*/
    }
  }
  .dashboard-line3 {
    position: relative;
    padding-left: 80px;
    .tracking-title {
      display: flex;
      justify-content: space-between;
      .title-svg {
        color: #fff;
        width: 36px;
        height: 24px;
      }
    }
    .map {
      position: relative;
      height: 125px;
      box-sizing: border-box;
      padding-top: 86px;
      .Progress {
        width: 100%;
        height: 100%;
        .step {
          width: 220px;
          height: 50px;
          display: inline-block;
          position: relative;
          .step-head {
            white-space: normal;
            position: relative;
            text-align: left;
            height: 100%;
            .step-head-line {
              position: absolute;
              border-color: inherit;
              background-color: transparent;
              border-bottom: 2px dashed #cacac7;
              height: 1px;
              top: 9px;
              left: 0;
              right: 0;
            }
            .isStateCurr {
              border-bottom: 2px solid #3f536a;
            }
            .step-head-icon {
              width: 50px;
              height: 50px;
              line-height: 50px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              background: #cccccc;
              box-sizing: border-box;
              border-color: inherit;
              position: absolute;
              top: -15px;
              z-index: 99;
              // left: 0;
              right: 0;
              border: 2px solid #cccccc;
              cursor: pointer;
              .icon-spot {
                text-align: center;
                color: #ffffff;
                font-size: 26px;
              }
              .icon-img {
                width: 24px;
              }
            }
            .halfPro {
              position: absolute;
              width: 110px;
              height: 2px;
              background-color: #3f536a;
              top: 50%;
              transform: translateY(-50%);
              .halfProBox {
                width: 40px;
                height: 40px;
                line-height: 40px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background: #3f536a;
                box-sizing: border-box;
                border-radius: 50%;
                border-color: inherit;
                position: absolute;
                top: -18px;
                z-index: 99;
                right: -10px;
                img {
                  width: 24px;
                }
              }
            }
            .isComplete {
              width: 40px;
              height: 40px;
              top: -9px;
              right: -12px;
              background: #3f536a;
            }
            .isCurr {
              background: #fff;
              border: 2px solid #132569;
              .icon-spot {
                color: #132569 !important;
              }
            }
            .location {
              background: #299be4;
              border: 2px solid #299be4;
              .icon-spot {
                color: #fff !important;
              }
            }
          }
          .step-main {
            text-align: left;
            letter-spacing: 0.03em;
            color: #132569;
            font-size: 18px;
            position: absolute;
            top: -90px;
            right: -150px;
            width: 200px;
            height: 80px;
            word-wrap: break-word;
            white-space: normal;
            word-break: break-all;
            p {
              margin: 10px 0;
              &:nth-child(1) {
                font-size: 16px;
              }
              &:nth-child(2) {
                font-family: montserratLight;
              }
            }
          }
          .locationText {
            color: #299be4;
          }
          &:first-child {
            width: 50px;
            .step-head {
              .step-head-line {
                height: 0;
              }
            }
            .step-main {
              left: 0px;
            }
          }
        }
      }
    }
    .searchInput {
      margin-bottom: 20px;
      width: 358px;
    }
    .model-main {
      display: flex;
      justify-content: space-between;

      .trackingleft {
        width: 48%;
      }
      .trackingright {
        width: 48%;
        height: 28vh;
        position: relative;
        // margin-top: 40px;
        .maskMap {
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          height: 60px;
          background-color: rgba(255, 255, 255, 0.7);
          .SQIN,
          .from,
          .in {
            display: inline-block;
          }
          .SQIN {
            font-size: 20px;
            color: $mainColor;
            width: 200px;
            height: 60px;
            box-sizing: border-box;
            padding-top: 20px;
            padding-left: 20px;
          }
          .from {
            vertical-align: top;
            color: $mainColor;
            height: 60px;
            width: 500px;
            float: right;
            text-align: right;
            box-sizing: border-box;
            padding-top: 10px;
            .from-title {
              text-align: left;
              font-size: 15px;
            }
            .to {
              margin: 15px 10px 0 10px;
            }
          }
          .in {
            text-align: left !important;
          }
          .inlast {
            margin-right: 20px;
          }
          .inline {
            margin: 0 20px;
          }
          .inaction,
          .inlast {
            div {
              &:nth-child(1) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
  .Location {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .current,
    .Estimate {
      display: flex;
      width: 350px;
      .left {
        font-size: 18px;
        color: $background2;
        width: 100px;
        box-sizing: border-box;
      }
      .last {
        width: 60px;
      }
      .right {
        .rightTitle {
          font-size: 20px;
          color: $mainColor;
          margin-bottom: 10px;
        }
        .address {
          font-size: 18px;
          line-height: 30px;
        }
        .time {
          font-size: 15px;
          color: $fontColor2;
        }
      }
    }
    .current {
      color: $background2;
    }
    .Estimate {
      color: $fontColor3;
    }
  }
}
</style>
